<template>
  <ERow no-gutters class="pa-1">
    <ECol cols="12" class="pa-0 text-center">
      <div v-if="footages.length > 0">
        <div class="grid-table pt-2">
          <div class="grid-row grid-header">
            <div v-for="(header, i) in headers" :key="i" class="grid-cell">
              {{ header }}
            </div>
          </div>
        </div>

        <div class="grid-table grid-table-body">
          <div class="grid-items-container">
            <div
              v-for="(footage, index) in footages"
              :key="index"
              class="w-100 mb-1"
            >
              <HddFootageItem
                :footage="footage"
                class="grid-surface"
                @delete-footage="openDeleteDialog"
                @edit-footage="editFootage"
              />
            </div>
          </div>
        </div>
      </div>
      <FootagesDialog
        :dialog="isFootageDialogOpen"
        :hdd-id="item.id"
        :footage="selectedFootage"
        @close-dialog="closeFotageDialog"
      />
      <v-btn
        color="primary"
        class="mr-1"
        small
        @click="isFootageDialogOpen = true"
      >
        Add Footage
      </v-btn>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import { PropType } from "vue"
import { Footage, Hdd } from "@evercam/shared/types"
import HddFootageItem from "@/components/hdds/HddFootageItem"
import FootagesDialog from "@/components/hdds/FootagesDialog"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "HddFootages",
  components: {
    HddFootageItem,
    FootagesDialog,
  },
  props: {
    item: {
      type: Object as PropType<Hdd>,
      required: true,
    },
  },
  data() {
    return {
      headers: ["Edit", "Camera", "Start Date", "End Date", "Delete"],
      isLoading: false,
      isFootageDialogOpen: false,
      selectedFootage: undefined,
    }
  },
  computed: {
    footages(): Footage[] {
      return this.item.footages as Footage[]
    },
  },
  methods: {
    async deleteFootage(footage) {
      try {
        await AdminApi.hdds.deleteFootage(this.item.id, footage.id)
        this.$root.$emit("refresh-report-table")
        this.$root.$emit("refresh-hdds-project-table")

        this.$notifications.success("Footage deleted successfully.")
      } catch (error) {
        this.$notifications.error({ text: "Delete footage failed", error })
      }
    },
    async openDeleteDialog(item) {
      if (
        await this.$confirmDialog.open({
          title: "Delete Footage",
          message: `Are you sure you want to delete the footage related to camera <span class='font-weight-bold'>${item.cameraName}</span>? <br> This action cannot be undone!`,
        })
      ) {
        this.deleteFootage(item)
      }
    },
    editFootage(footage) {
      this.selectedFootage = footage
      this.isFootageDialogOpen = true
    },
    closeFotageDialog() {
      this.isFootageDialogOpen = false
      this.selectedFootage = {}
    },
  },
}
</script>

<style lang="scss">
.simple-table {
  border-collapse: collapse;
  th {
    text-align: left;
  }
}
@import "~@evercam/shared/styles/mixins";
.ex-nvr-container {
  background: #f6f6f6;
}
.grid-table {
  width: 100%;
}
.grid-header {
  padding: 6px 12px;
  background: #c8d2d7;
  border: 1px solid #bcc9d0;
  margin-bottom: 10px;
  border-radius: 0.5em;
  box-shadow: 0 5px 16px -13px #000;
}
.grid-table-body {
  width: 100%;
  overflow: auto;
  @include custom-scrollbar();
}
.grid-items-container {
  overflow: hidden;
  border-radius: 0.5em;
}
.grid-row {
  gap: 10px;
  display: grid;
  grid-template-columns: 0.5fr 2.25fr 2.5fr 2.5fr 0.5fr;
  align-items: center;
  justify-content: start;
}
.grid-surface {
  border-radius: 0.5em;
  overflow: hidden;
  background: rgb(233, 236, 239);
  border: 1px solid rgba(188, 201, 208, 0.58);
}
</style>
