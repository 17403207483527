<template>
  <ERow no-gutters class="mx-0">
    <v-card flat tile class="pa-2 subtitle-2 grey--text text--darken-2">
      Event:
    </v-card>
    <v-card class="px-1 pa-0" flat tile>
      <v-select
        v-model="filters"
        chips
        small-chips
        deletable-chips
        :items="filtersItems"
        multiple
        class="pt-0 mt-0 caption"
        placeholder="Select Event"
        hide-details
      ></v-select>
    </v-card>
  </ERow>
</template>

<script>
import LOG_TYPES from "@evercam/shared/constants/logTypes"

export default {
  name: "CameraLogsFilters",
  props: {
    areAllFiltersChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: [],
      checkAll: false,
      filtersItems: [
        {
          text: "Private Note",
          value: `${LOG_TYPES.PRIVATE_NOTE}`,
        },
        {
          text: "Public Note",
          value: `${LOG_TYPES.PUBLIC_NOTE}`,
        },
        {
          text: "Camera Status",
          value: `${LOG_TYPES.ONLINE},${LOG_TYPES.OFFLINE},${LOG_TYPES.VH_STATUS},${LOG_TYPES.DECOMMISSIONED},${LOG_TYPES.ON_HOLD},${LOG_TYPES.WAITING},${LOG_TYPES.UNDER_MAINTENANCE},${LOG_TYPES.WAITING_FOR_SITE_VISIT}`,
        },
        {
          text: "SIM / SMS",
          value: `${LOG_TYPES.LINK_SIM_WITH_ROUTER},${LOG_TYPES.UNLINK_SIM_FROM_ROUTER}`,
        },
        {
          text: "Cloud Recording Settings",
          value: `${LOG_TYPES.CR_UPDATED},${LOG_TYPES.CR_CREATED}`,
        },
        {
          text: "Sharing Settings",
          value: `${LOG_TYPES.SHARE_REQUEST},${LOG_TYPES.DELETE_SHARE_REQUEST},${LOG_TYPES.SHARE},${LOG_TYPES.UPDATE_SHARE},${LOG_TYPES.DELETE_SHARE}`,
        },
        {
          text: "Camera Settings",
          value: `${LOG_TYPES.CREATE},${LOG_TYPES.UPDATE},${LOG_TYPES.DELETE},${LOG_TYPES.TRANSFER_OWNERSHIP},${LOG_TYPES.DEVICE_SETTINGS}`,
        },
      ],
    }
  },
  watch: {
    filters(v) {
      this.checkAll = this.filters.length === this.filtersItems.length
      this.$emit("input", v)
    },
    areAllFiltersChecked: {
      immediate: true,
      handler(val) {
        this.selectAll(val)
      },
    },
  },
  methods: {
    selectAll(val) {
      this.filters = val
        ? this.filtersItems.reduce((acc, item) => [item.value, ...acc], [])
        : []
    },
  },
}
</script>

<style>
.logs-filter {
  max-height: 15px;
}
</style>
