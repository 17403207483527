<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <HddsTable
        v-if="projectId || companyId"
        :hdds-list="hddsList"
        :is-loading="isLoading"
        :hdds-colors="hddsColors"
      />
    </ECol>
    <ECol cols="12" class="py-0">
      <HddsPerCamera
        v-if="projectId || companyId"
        :hdds-list="hddsList"
        :cameras="cameras"
        :hdds-colors="hddsColors"
        :is-loading="isLoading"
      />
    </ECol>
  </ERow>
</template>

<script lang="ts">
import HddsTable from "@/components/hdds/HddsTable"
import HddsPerCamera from "@/components/hdds/HddsPerCamera"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default Vue.extend({
  name: "ProjectHdds",
  components: {
    HddsTable,
    HddsPerCamera,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },

    companyId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: true,
      hddsList: [],
      cameras: [],
      hddsColors: {},
      colors: [
        "#fab8c4",
        "#f8bd9a",
        "#e4c999",
        "#d6ce99",
        "#c7d299",
        "#a8d799",
        "#9ad7bd",
        "#9ad6cc",
        "#9cd5d7",
        "#9dd4e2",
        "#9ed1f5",
        "#cac9f9",
        "#e5bdf9",
        "#f9b0f1",
        "#fab5db",
      ],
    }
  },
  watch: {
    companyId() {
      this.getProjectHdds()
    },
    projectId() {
      this.getProjectHdds()
    },
  },
  async mounted() {
    this.isLoading = true
    await this.getProjectHdds()
    this.$root.$on(
      "refresh-hdds-project-table",
      async () => await this.getProjectHdds()
    )
  },
  methods: {
    async getProjectHdds() {
      if (!this.companyId && !this.projectId) {
        return
      }
      try {
        let params = {
          projectId: this.projectId,
          companyId: this.companyId,
        }
        let response = await AdminApi.hdds.getHdds(params)
        let hdds = []

        response.forEach((hdd, index) => {
          this.hddsColors = {
            ...this.hddsColors,
            [hdd.id]: this.colors[index],
          }
        })
        response.forEach((hdd, _index) => {
          hdds.push({
            id: hdd.id,
            location: hdd.location,
            serialNumber: hdd.serialNumber,
            hddStatus: hdd.hddStatus,
            shelfBlock: hdd.shelfBlock,
            type: hdd.type,
            size: hdd.size,
            format: hdd.format,
            footages: hdd.footages,
            reportedCapacity: hdd.reportedCapacity,
            reportedFreeSpace: hdd.reportedFreeSpace,
            reportedProperty: hdd.reportedProperty,
            reportedPath: hdd.reportedPath,
            reportedHddType: hdd.reportedHddType,
            errorStatus: hdd.errorStatus,
            canExpand: hdd.footages.length > 0,
          })
        })

        this.hddsList = hdds
      } catch (error) {
        this.$notifications.error({
          text: "Failed to fetch HDDs ",
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
