<template>
  <v-dialog v-model="dialog" max-width="450">
    <v-card>
      <v-card-title primary-title>
        Add New {{ isPublicNote ? "Public " : "" }}Note
      </v-card-title>
      <v-card-text>
        <CameraPublicNoteEditor
          v-if="isPublicNote && dialog"
          class="mb-4"
          @change="(v) => (note = v)"
        />
        <v-textarea
          v-else
          v-model="note"
          filled
          class="ma-0"
          name="input-7-4"
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="note-actions-top">
        <v-switch
          v-model="pinNote"
          color="primary"
          class="mt-0 pt-0 pl-2"
          label="Pin Note"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :loading="isNoteLoading"
          :disabled="!note"
          @click="addNote()"
        >
          Add
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            () => {
              dialog = false
              note = ''
            }
          "
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CameraPublicNoteEditor from "@/components/offlineCameras/CameraPublicNoteEditor"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "CameraNoteDialog",
  components: {
    CameraPublicNoteEditor,
  },
  props: {
    camera: {
      type: Object,
      required: true,
    },
    noteType: {
      type: String,
      required: true,
    },
    isPublicNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      note: "",
      pinNote: false,
      dialog: false,
      isNoteLoading: false,
    }
  },
  watch: {
    "$attrs.value": {
      immediate: true,
      handler(value) {
        this.dialog = value
      },
    },
    dialog(value) {
      this.$emit("input", value)
    },
  },
  methods: {
    async addNote() {
      try {
        this.isNoteLoading = true
        let response = await AdminApi.cameras.saveNote(this.camera.exid, {
          customMessage: this.note,
          action: this.noteType,
          pinned: this.pinNote,
        })
        this.$emit("success")
        this.note = ""
        this.pinNote = false
        this.dialog = false
        this.$notifications.success(response.message)
      } catch (error) {
        this.$notifications.error({ text: "Could not add note!", error })
      } finally {
        this.isNoteLoading = false
      }
    },
  },
}
</script>

<style scoped>
.note-actions-top {
  margin-top: -50px;
}
</style>
