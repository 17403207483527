var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"custom-dialog",staticStyle:{"height":"400px"},attrs:{"value":_vm.dialog,"max-width":"600","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Add a new Footage")])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('ValidationObserver',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function({ invalid, passes }){return [_c('v-card-text',[_c('v-form',{staticStyle:{"width":"100%"}},[_c('ERow',{staticClass:"d-flex align-center justify-space-between",attrs:{"no-gutters":""}},[_c('ECol',{attrs:{"cols":"12"}},[(_vm.footage && _vm.footage.cameraName)?_c('v-text-field',{attrs:{"readonly":"","dense":"","outlined":"","disabled":"","value":_vm.footage.cameraName}}):_c('Autocomplete',{attrs:{"item-value":"exid","item-text":"name","label":"Camera","no-filter":"","rules":"required","cache-items":false,"resource":"cameras"},model:{value:(_vm.selectedCamera),callback:function ($$v) {_vm.selectedCamera=$$v},expression:"selectedCamera"}})],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ValidationProvider',{attrs:{"name":"startDate","rules":{
                      required: true,
                      date: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","outlined":"","placeholder":"Start Date","label":"Start Date","error-messages":errors,"dense":""},on:{"click:clear":function($event){_vm.startDate = ''}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.startDateRangePicker),callback:function ($$v) {_vm.startDateRangePicker=$$v},expression:"startDateRangePicker"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.startDateRangePicker = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"startTime","rules":{
                  time: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Enter Time (HH:MM)","placeholder":"HH:MM","maxlength":"5","error-messages":errors,"dense":"","counter":"","outlined":""},on:{"input":function($event){return _vm.formatTime('startTime')}},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})]}}],null,true)})],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ValidationProvider',{attrs:{"name":"endDate","rules":{
                      date: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","outlined":"","placeholder":"End Date","label":"End Date","error-messages":errors,"dense":""},on:{"click:clear":function($event){_vm.endDate = ''}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.endDateRangePicker),callback:function ($$v) {_vm.endDateRangePicker=$$v},expression:"endDateRangePicker"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.endDateRangePicker = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"endTime","rules":{
                  time: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Enter Time (HH:MM)","placeholder":"HH:MM","maxlength":"5","error-messages":errors,"dense":"","counter":"","outlined":""},on:{"input":function($event){return _vm.formatTime('endTime')}},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})]}}],null,true)})],1)],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":invalid},on:{"click":function($event){return passes(_vm.saveFootage)}}},[_vm._v("\n          Save\n        ")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }