<template>
  <v-dialog
    :value="dialog"
    max-width="600"
    persistent
    class="custom-dialog"
    style="height: 400px"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span>Add a new Footage</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" disabled>
        <v-card-text>
          <v-form style="width: 100%">
            <ERow no-gutters class="d-flex align-center justify-space-between">
              <ECol cols="12">
                <v-text-field
                  v-if="footage && footage.cameraName"
                  readonly
                  dense
                  outlined
                  disabled
                  :value="footage.cameraName"
                >
                </v-text-field>
                <Autocomplete
                  v-else
                  v-model="selectedCamera"
                  item-value="exid"
                  item-text="name"
                  label="Camera"
                  no-filter
                  rules="required"
                  :cache-items="false"
                  resource="cameras"
                />
              </ECol>

              <ECol cols="6">
                <v-menu
                  ref="menu"
                  v-model="startDateRangePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="startDate"
                      :rules="{
                        required: true,
                        date: true,
                      }"
                    >
                      <v-text-field
                        v-model="startDate"
                        clearable
                        outlined
                        placeholder="Start Date"
                        label="Start Date"
                        :error-messages="errors"
                        v-bind="attrs"
                        dense
                        v-on="on"
                        @click:clear="startDate = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="startDateRangePicker = false"
                  ></v-date-picker>
                </v-menu>
              </ECol>
              <ECol cols="5">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="startTime"
                  :rules="{
                    time: true,
                  }"
                >
                  <v-text-field
                    v-model="startTime"
                    label="Enter Time (HH:MM)"
                    placeholder="HH:MM"
                    maxlength="5"
                    :error-messages="errors"
                    dense
                    counter
                    outlined
                    @input="formatTime('startTime')"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="6">
                <v-menu
                  ref="menu"
                  v-model="endDateRangePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="endDate"
                      :rules="{
                        date: true,
                      }"
                    >
                      <v-text-field
                        v-model="endDate"
                        clearable
                        outlined
                        placeholder="End Date"
                        label="End Date"
                        :error-messages="errors"
                        v-bind="attrs"
                        dense
                        v-on="on"
                        @click:clear="endDate = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="endDateRangePicker = false"
                  ></v-date-picker>
                </v-menu>
              </ECol>
              <ECol cols="5">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="endTime"
                  :rules="{
                    time: true,
                  }"
                >
                  <v-text-field
                    v-model="endTime"
                    label="Enter Time (HH:MM)"
                    placeholder="HH:MM"
                    maxlength="5"
                    :error-messages="errors"
                    dense
                    counter
                    outlined
                    @input="formatTime('endTime')"
                  />
                </ValidationProvider>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="passes(saveFootage)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  components: { Autocomplete },
  props: {
    hddId: {
      type: Number,
      required: true,
    },
    footage: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      defaultItem: {
        cameraId: "",
        startDate: "",
        endDate: "",
      },
      selectedCamera: undefined,
      startDateRangePicker: false,
      endDateRangePicker: false,
      startDate: "",
      endDate: "",
      startTime: "00:00",
      endTime: "",
    }
  },
  computed: {
    ...mapStores(useReportStore),
  },
  watch: {
    async selectedCamera(camera) {
      if (camera) {
        this.defaultItem.cameraId = camera.id
      }
    },
    async dialog(value) {
      if (value && this.footage?.cameraName) {
        if (this.footage.startDate) {
          const startMoment = this.$moment(this.footage.startDate)
          this.startDate = startMoment.format("YYYY-MM-DD")
          this.startTime = startMoment.format("HH:mm")
        }

        if (this.footage.endDate) {
          const endMoment = this.$moment(this.footage.endDate)
          this.endDate = endMoment.format("YYYY-MM-DD")
          this.endTime = endMoment.format("HH:mm")
        }

        this.defaultItem = { ...this.footage }
      }
    },
    startDate() {},
  },
  methods: {
    close() {
      this.selectedCamera = undefined
      this.startDate = ""
      this.endDate = ""
      this.startTime = "00:00"
      this.endTime = ""
      this.defaultItem = {
        cameraId: "",
        startDate: "",
        endDate: "",
      }

      this.$emit("close-dialog")
    },
    saveFootage() {
      this.reportStore.loading = true
      if (
        (this.startDate && !this.startTime) ||
        (this.endDate && !this.endTime)
      ) {
        this.$notifications.error({
          text: "Make sure to select the time",
        })

        this.reportStore.loading = false

        return
      }
      this.defaultItem.startDate = this.$moment(
        this.startDate + " " + this.startTime + ":00"
      )
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
      if (this.defaultItem.endDate || this.endDate) {
        this.defaultItem.endDate = this.$moment(
          this.endDate + " " + this.endTime + ":00"
        )
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss[Z]")
      }

      this.$nextTick(async () => {
        try {
          if (this.footage?.cameraName) {
            await AdminApi.hdds.updateFootage(
              this.hddId,
              this.footage.id,
              this.defaultItem
            )
          } else {
            await AdminApi.hdds.saveFootage(this.hddId, this.defaultItem)
          }

          this.close()
          this.$root.$emit("refresh-hdds-project-table")
          this.reportStore.searchFilters = {}
          this.reportStore.loading = false

          this.$notifications.success("Footage updated successfully.")
        } catch (error) {
          this.$notifications.error({ text: "Updating footage failed", error })
        }
      })
    },
    formatTime(input) {
      if (this[input].length === 2 && !this[input].includes(":")) {
        this[input] = `${this[input]}:`
      }
    },
  },
}
</script>

<style>
/* Ensure the picker displays correctly inside a dialog */
.custom-picker {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

/* Override dialog or parent CSS if necessary */
.v-dialog__content {
  overflow: visible !important;
}
.custom-dialog .v-card {
  height: 600px !important;
  max-height: none !important; /* Set your custom height here */
  overflow: visible !important;
}
</style>
