export default [
  {
    value: "edit",
    text: "",
    width: 30,
    visible: true,
    align: "center",
    sortable: false,
    filterable: false,
  },
  {
    value: "id",
    text: "ID",
    width: 30,
    visible: true,
    filterable: true,
  },
  {
    value: "location",
    text: "Location",
    width: 50,
    visible: true,
    sortable: false,
  },
  {
    value: "serialNumber",
    text: "Serial #",
    visible: true,
  },
  {
    value: "hddStatus",
    text: "Status",
    visible: true,
  },
  {
    value: "shelfBlock",
    text: "Shelf Block",
    visible: true,
    sortable: false,
  },
  {
    value: "type",
    text: "Type",
    visible: true,
  },
  {
    value: "size",
    text: "Size",
    visible: true,
  },
  {
    value: "format",
    text: "Format",
    visible: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    visible: true,
  },
  {
    value: "error_status",
    text: "Error Status",
    visible: false,
    width: 50,
  },
  {
    value: "reported_capacity",
    text: "Reported Capacity",
    visible: false,
    width: 50,
  },
  {
    value: "reported_free_space",
    text: "Reported Free Space",
    visible: false,
    width: 50,
  },
  {
    value: "reported_property",
    text: "Reported Property",
    visible: false,
    width: 50,
  },
  {
    value: "reported_path",
    text: "Reported Path",
    visible: false,
    width: 50,
  },
  {
    value: "reported_hdd_type",
    text: "Reported Type",
    visible: false,
    width: 50,
  },
  {
    value: "delete",
    text: "",
    width: 30,
    visible: true,
    align: "center",
    sortable: false,
  },
]
