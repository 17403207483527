<template>
  <div class="ex-nvr-camera-item">
    <div class="grid-row ex-nvr-card">
      <div class="grid-cell pl-3">
        <v-btn x-small icon @click="editFootage()">
          <v-icon color="primary"> fas fa-pencil </v-icon>
        </v-btn>
      </div>

      <div class="grid-cell pl-3">
        <span>
          <a
            @click="
              cameraDialogStore.openDialog({ camera: footage.cameraExid })
            "
            >{{ footage.cameraName }}</a
          >
        </span>
      </div>

      <!-- DATES -->
      <div class="grid-cell">
        <span> {{ formatDate(footage.startDate) }} </span>
      </div>
      <div class="grid-cell">{{ formatDate(footage.endDate) }}</div>

      <div class="grid-cell">
        <v-btn x-small icon @click="deleteFootage()">
          <v-icon color="error"> fas fa-trash </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Footage } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
export default Vue.extend({
  props: {
    footage: {
      type: Object as PropType<Footage>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    cameraDialogUrl() {
      return `${document.location.href}#dialog=true&exid=${this.footage.cameraExid}`
    },
  },
  methods: {
    editFootage() {
      this.$emit("edit-footage", this.footage)
    },
    deleteFootage() {
      this.$emit("delete-footage", this.footage)
    },
  },
})
</script>

<style scoped lang="scss">
.ex-nvr-card {
  position: relative;
  .expand-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
</style>
